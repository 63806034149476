import React from 'react';

import styles from './index.module.scss';

const Turkish = ({
  heroSrc, state, onCheckboxChange, submit, cantAttend,
}) => (
  <React.Fragment>
    <h1>
        {'Ece & Furkan'}
      </h1>
      <h2>
        {'28 Mart 2020'}
      </h2>
      <div className={styles.imageContainer}>
        <img src={heroSrc} alt="hero" />
      </div>
      <p>
        {`Merhaba ${state.name},`}
      </p>
      <p>
        Biz evleniyoruz!
      </p>
      <p>
        Bu günü seninle kutlamayı ve mutluluğumuzu paylaşmayı dört gözle bekliyoruz. 
      </p>
      <p>
        Düğünümüz <b>28 Mart Cumartesi</b> akşamı Bursa’da gerçekleşecek.
      </p>
      <p>
        Öncesinde 25 Mart Çarşamba tarihinde Kuşadası’nda kına gecesi, ve 27 Mart Cuma akşamında da Yenişehir’de nikah töreni yapılacak.
      </p>
      <p>
        Bu heyecanlı anlarımızda bizimle eğlenmek istersen, en kısa zamanda aşağıdaki formu kullanarak LCV yapmanı rica ediyoruz. 
      </p>
      <p>
        <span className={styles.signature}>Sevgiler,</span>
        <br />
        {'Ece & Furkan'}
      </p>
      <hr />
      <div className={styles.checkboxes}>
        <h2>{'Katılmak İstediklerim'}</h2>
        <div className={styles.container}>
          <label>
            <input
              type="checkbox"
              name="willAttendHennaNight"
              checked={state.willAttendHennaNight}
              onChange={onCheckboxChange}
            />
            {'Kına gecesi'}
          </label>
          <label>
            <input
              type="checkbox"
              name="willAttendCeremony"
              checked={state.willAttendCeremony}
              onChange={onCheckboxChange}
            />
            {'Nikah'}
          </label>
          <label>
            <input
              type="checkbox"
              name="willAttendWedding"
              checked={state.willAttendWedding}
              onChange={onCheckboxChange}
            />
            {'Düğün'}
          </label>
          {state.canPlusOne && (
            <label>
              <input
                type="checkbox"
                name="willBringPlusOne"
                checked={state.willBringPlusOne}
                onChange={onCheckboxChange}
              />
              {'+1 getireceğim'}
            </label>
          )}
        </div>
        { state.willAttend === false && (
          <span className="error">
            {'Katılamayacağınızı işaretlediniz.'}
          </span>
        )}
        <div className={styles.buttons}>
          <button
            onClick={submit}
            className={`${state.form.loading ? 'disabled' : ''}`}
          >
            {state.form.loading ? 'Kaydediliyor...' : 'Cevaplarımı kaydet'}
          </button>
          <button 
            onClick={cantAttend}
            className={`red ${state.form.loading ? 'disabled' : ''}`}
          >
            {state.form.loading ? 'Kaydediliyor...' : 'Katılamayacağım'}
          </button>
        </div>
        {state.form.message && (
          <span className={state.form.error ? 'error' : ''}>
            {state.form.message}
          </span>
        )}
      </div> 
  </React.Fragment>
);

export default Turkish;
