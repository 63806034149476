import React from 'react';

import styles from '../index.module.scss';

const English = ({ heroSrc, state }) => {

  let attendanceMessage = '';

  if (state.willAttend) {
    attendanceMessage += 'You are attending to';

    let count = 0;

    if (state.willAttendWedding) {
      attendanceMessage += ' the wedding'
      count++;
    }

    if (state.willAttendHennaNight) {
      attendanceMessage += `${(count === 1 && !state.willAttendCeremony ? ' and ' : (count === 0 ? '' : ', '))} henna night`;
      count++;
    }

    if (state.willAttendCeremony) {
      attendanceMessage += `${(count === 0 ? '' : ' and')} civil ceremony`;
      count++;
    }

    if (count === 0) {
      attendanceMessage = 'You answered as not attending.';
    } else {
      if (state.willBringPlusOne) {
        attendanceMessage += ' with your plus one.';
      } else {
        attendanceMessage += '.'
      }
    }
  } else {
    attendanceMessage = 'You answered as not attending.';
  }

  return (
    <React.Fragment>
      <h1>
          {'Ece & Furkan'}
        </h1>
        <h2>
          {'28 March 2020'}
        </h2>
        <div className={styles.imageContainer}>
          <img src={heroSrc} alt="hero" />
        </div>
        <p>
          {`Hello ${state.name},`}
        </p>
        <p>
          Thanks for your answer!
        </p>
        <p>
          You can find your answer below. If you want to change your answer, feel free to contact Ece or Furkan directly!
        </p>
        <p>
          Our wedding will be 
          held in <a href="https://en.wikipedia.org/wiki/Turkey" target="__blank">Turkey</a>, and 
          includes three different events; <i>the henna night</i>, <i>civil ceremony</i> and <i>the wedding</i>.
        </p>
        <p>
          We are very much looking forward to sharing our big days with you. You can find more information about these events below.
        </p>
        <p>
          <span className={styles.signature}>Lots of love,</span>
          <br />
          {'Ece & Furkan'}
        </p>
        <hr />
        <div className={styles.checkboxes}>
          <h2 style={{ marginBottom: 0, lineHeight: '1.2em'}}>{attendanceMessage}</h2>
        </div>
        <hr />
        <div className={styles.moreInfo}>
          <h3>The Wedding</h3>
          <p>
            The wedding celebration, which is <b>the most important event</b> among all three, is on 
            the <b>28th of March, Saturday</b> starting from around <i>19.30 until 02.30</i>. Our 
            venue is in <a href="https://goo.gl/maps/oyTMe5Tx8VNF1y317" target="__blank">Bursa</a>, one 
            of <a href="https://en.wikipedia.org/wiki/List_of_largest_cities_and_towns_in_Turkey" target="__blank">the biggest cities in Turkey</a>.
            The wedding will start with a dinner and an afterparty will follow. As it is a Turkish
            wedding, we will be dancing the night away from the beginning until the last man standing.
          </p>
          <h3>The Henna Night</h3>
          <p>
            The henna night is on the <b>25th of March, Wednesday</b>, in Ece’s hometown <a href="https://goo.gl/maps/HaWs21JeM3VHvE2C9" target="__blank">Kuşadası</a>. Henna
            night is a long-rooted tradition in Turkish culture where the focus is mostly on belly 
            dancing in <a href="https://www.google.com/search?q=bindall%C4%B1&tbm=isch&source=univ&sa=X&ved=2ahUKEwiPjJLe6bDlAhXSxMQBHZG9A00QsAR6BAgHEAE&cshid=1571780386148775&biw=1920&bih=1098" target="__blank">traditional cloths</a> (lots of fun!). This event starts at 19.00 and ends around 
            midnight. Snacks and drinks will be served.
          </p>
          <h3>Civil Ceremony</h3>
          <p>
            The civil ceremony will be held on the <b>27th of March, Friday</b> between 19.30-22.00 
            in <a href="https://goo.gl/maps/GwUShrk8EsJ7BYod6" target="__blank">Yenişehir</a>, Furkan’s hometown. These ceremonies are held 
            by government officials in Turkey, initiated with a very brief speech. Following this, 
            we will be greeting each guest, and taking photos. We will also be serving snacks and 
            drinks at this event. 
          </p>
          <h3>Travel Options</h3>
          <p>
            We understand that you might be unfamiliar to a wedding with three events. So we present two options for our guests;
          </p>
          <p>
            If you are short of time and wouldn’t like to take days off from work; you can fly to 
            one of the Istanbul airports (<a href="https://goo.gl/maps/byqqW1SapiMJsRiA7" target="__blank">Sabiha Gökçen</a> or <a href="https://g.page/istairport?share" target="__blank">Istanbul Airport</a>) on 
            27th of March Friday night or early Saturday the 28th. From <a href="https://goo.gl/maps/rPZ2qZyH5f8cKe9b8" target="__blank">Istanbul</a>, you can take a ferry to <a href="https://goo.gl/maps/oyTMe5Tx8VNF1y317" target="__blank">Bursa</a> which 
            takes about 1,5 hours, or a bus that takes around 2 hours. This way you will be in time 
            for the wedding and can return on Sunday if you wish. 
          </p>
          <p>
            If you would like to turn this opportunity into a small vacation, and have the whole 
            Turkish wedding experience, you can check for flights to <a href="https://goo.gl/maps/PTwRxvrWXWS9uJtCA" target="__blank">Izmir</a> (usually they have a layover in Istanbul, and it’s followed by a 45 min. domestic flight). After 
            the henna night, we will be traveling to Bursa as a group, for which we will organize bus shuttles. 
          </p>
          <hr />
          <p>
            We know that this is a whole lot of information, so feel free to contact us if you have any 
            questions. As the date approaches, we will provide more tips on accomodation, things to do, and getting around. 
            Don't forget to <i>RSVP</i> as soon as possible.
          </p>
        </div>
    </React.Fragment>
  );
};

export default English;
