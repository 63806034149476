/* global window */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import axios from 'axios';

import Loader from './loader';
import English from './english';
import Turkish from './turkish';
import EnglishInfo from './info/english';
import TurkishInfo from './info/turkish';

import styles from './index.module.scss';

import hero from './hero.png';
import heroMobile from './hero_mobile.png';

const NotFound = () => (
  <div className={styles.errorPage}>
    <h1>
      {'404'}
    </h1>
    <p>
      {'The page you are looking for is not found.'}
    </p>
  </div>
);

const errorMessage = {
  en: 'Could not save your answers at the moment. Try again in a moment. If the issue persists, feel free to contact Ece or Furkan directly!',
  tr: 'Cevaplarınız kaydedilemedi. Lütfen tekrar deneyin. Eğer bu sorun devam ederse Ece veya Furkan ile iletişime geçin.',
};

const App = () => {
  const [state, setState] = useState({
    willAttendHennaNight: false,
    willAttendCeremony: false,
    willAttendWedding: false,
    willBringPlusOne: false,
    loading: true,
    error: false,
    form: {
      loading: false,
      error: false,
    }
  });

  const [isMobile, setMobile] = useState(window.innerWidth < 768);

  const savedLanguage = window.localStorage.getItem('ece-furkan-wedding_language');

  const [language, setLanguage] = useState(savedLanguage || 'en');

  const code = useRef(null);

  const onCheckboxChange = useCallback((event) => {
    if (state.form.loading) {
      return;
    }

    const { name, type, checked, value } = event.target;

    setState((state) => ({
      ...state,
      [name]: type === 'checkbox' ? checked : value,
    }));
  }, [state.form.loading]);

  const submit = useCallback(() => {
    if (!code.current) {
      return;
    }

    if (state.form.loading) {
      return;
    }
    
    setState({
      ...state,
      form: {
        loading: true,
        error: false,
      }
    });

    axios.post(
      `/api/save/${code.current}`,
      {
        willAttendHennaNight: state.willAttendHennaNight,
        willAttendCeremony: state.willAttendCeremony,
        willAttendWedding: state.willAttendWedding,
        willBringPlusOne: state.willBringPlusOne,
      },
    ).then(({ data }) => {
        setState((state) => ({
          ...state,
          ...data.item,
          form: {
            loading: false,
            error: false,
            message: data.message,
          }
        }));
    }).catch((error) => {
      setState((state) => ({
        ...state,
        form: {
          loading: false,
          error: true,
          message: errorMessage[language],
        }
      }));
    });

  }, [state, language]);

  const cantAttend = useCallback(() => {
    if (!code.current) {
      return;
    }

    if (state.form.loading) {
      return;
    }
    
    setState((state) => ({
      ...state,
      willAttendHennaNight: false,
      willAttendCeremony: false,
      willAttendWedding: false,
      willBringPlusOne: false,
      form: {
        loading: true,
        error: false,
      }
    }));
    
    axios.post(`/api/will-not-attend/${code.current}`)
      .then(({ data }) => {
        setState((state) => ({
          ...state,
          ...data.item,
          form: {
            loading: false,
            error: false,
            message: data.message,
          }
        }));
      }).catch((error) => {
        setState((state) => ({
          ...state,
          form: {
            loading: false,
            error: true,
            message: errorMessage[language],
          }
        }));
      });
  }, [language, state.form.loading]);

  const selectLanguage = useCallback((language) => {
    window.localStorage.setItem('ece-furkan-wedding_language', language);

    setLanguage(language);
  }, []);

  useEffect(() => {
    let userCode;

    try {
      userCode = window.location.pathname.split('/')[1];
    } catch (error) {
      setState((state) => ({
        ...state, 
        loading: false, 
        error: true
      }));
      return;
    }

    if (userCode.trim().length === 0) {
      setState((state) => ({
        ...state, 
        loading: false, 
        error: true
      }));
      return;
    }

    code.current = userCode;

    axios.get(`/api/fetch/${code.current}`)
    .then(({ data }) => {
      setState((state) => ({
        ...state,
        ...data,
        error: false,
        loading: false,
      }));
      
      if (!savedLanguage) {
        setLanguage(data.language || 'en');
      }
    }).catch(({ response }) => {
      setState((state) => ({
        ...state, 
        loading: false, 
        error: true
      }));
    });
  }, [savedLanguage]);

  useEffect(() => {
    const onResize = () => {
      setMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  let heroSrc;

  if (isMobile) {
    heroSrc = heroMobile;
  } else {
    heroSrc = hero;
  }

  if (state.loading) {
    return <Loader />;
  } else if (state.error) {
    return <NotFound />;
  }

  return (
    <div className={styles.app}>
      <div className={styles.languageSelector}>
        <span className={language === 'en' ? styles.selected : null} onClick={() => { selectLanguage('en') }}>EN</span> | <span className={language === 'tr' ? styles.selected : null} onClick={() => { selectLanguage('tr') }}>TR</span>
      </div>
      {state.canEdit ? (
        <>
          {language === 'tr' ? (
            <Turkish
              heroSrc={heroSrc}
              state={state}
              onCheckboxChange={onCheckboxChange}
              submit={submit}
              cantAttend={cantAttend}
            />
          ) : (
            <English
              heroSrc={heroSrc}
              state={state}
              onCheckboxChange={onCheckboxChange}
              submit={submit}
              cantAttend={cantAttend}
            />
          )}
        </>
      ) : (
        <>
          {language === 'tr' ? (
            <TurkishInfo
              heroSrc={heroSrc}
              state={state}
            />
          ) : (
            <EnglishInfo
              heroSrc={heroSrc}
              state={state}
            />
          )}
        </>
      )}
    </div>
  );
};

export default App;
