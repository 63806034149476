import React from 'react';

import styles from '../index.module.scss';

const Turkish = ({ heroSrc, state }) => {

  let attendanceMessage = '';

  if (state.willAttend) {
    attendanceMessage += '';

    let count = 0;

    if (state.willAttendWedding) {
      attendanceMessage += 'Düğüne'
      count++;
    }

    if (state.willAttendHennaNight) {
      attendanceMessage += `${(count === 1 && !state.willAttendCeremony ? ' ve k' : (count === 0 ? 'K' : ', k'))}ına gecesine`;
      count++;
    }

    if (state.willAttendCeremony) {
      attendanceMessage += `${(count === 0 ? 'N' : ' ve n')}ikaha`;
      count++;
    }

    if (count === 0) {
      attendanceMessage = 'Katılamayacağınızı işaretlediniz.';
    } else {
      if (state.willBringPlusOne) {
        attendanceMessage += " +1'im ile katılacağım.";
      } else {
        attendanceMessage += ' katılacağım.'
      }
    }
  } else {
    attendanceMessage = 'Katılamayacağınızı işaretlediniz.';
  }
  
  return (
    <React.Fragment>
      <h1>
          {'Ece & Furkan'}
        </h1>
        <h2>
          {'28 Mart 2020'}
        </h2>
        <div className={styles.imageContainer}>
          <img src={heroSrc} alt="hero" />
        </div>
        <p>
          {`Merhaba ${state.name},`}
        </p>
        <p>
          Cevapların için teşekkürler!
        </p>
        <p>
          Verdiğin cevapları aşağıda bulabilirsin. Eğer cevaplarını değiştirmek istersen Ece veya Furkan ile iletişime geçebilirsin. 
        </p>
        <p>
          Düğünümüz <b>28 Mart Cumartesi</b> akşamı Bursa’da gerçekleşecek.
        </p>
        <p>
          Öncesinde 25 Mart Çarşamba tarihinde Kuşadası’nda kına gecesi, ve 27 Mart Cuma akşamında da Yenişehir’de nikah töreni yapılacak.
        </p>
        <p>
          Bu günü seninle kutlamayı ve mutluluğumuzu paylaşmayı dört gözle bekliyoruz.
        </p>
        <p>
          <span className={styles.signature}>Sevgiler,</span>
          <br />
          {'Ece & Furkan'}
        </p>
        <hr />
        <div className={styles.checkboxes}>
          <h2 style={{ marginBottom: 0, lineHeight: '1.2em'}}>{attendanceMessage}</h2>
        </div>
        <div className={styles.moreInfo} style={{ paddingTop: 0 }}/>
    </React.Fragment>
  );
};

export default Turkish;
